import { useAccessToken } from "@/hooks/auth/use-access-token";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import {
  getPurchaseControllerHandleTossFailureQueryKey,
  usePurchaseControllerHandleTossFailure,
} from "@shared/generated/api/fn/kac/purchase/purchase";
import { Button } from "@shared/lib/components/common/atom/button";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const FailTemplate = () => {
  const { t } = useTranslation();
  const { accessToken, isLoading: isTokenLoading } = useAccessToken();
  const router = useRouter();
  const { courseId, cycleId, message, orderId, code } = router.query;

  useEffect(() => {
    // 로딩이 완료되었고, accessToken이 없는 경우 리다이렉트
    if (!isTokenLoading && !accessToken) {
      router.push("/");
    }
  }, [accessToken, isTokenLoading, router]);

  const { isLoading, status } = usePurchaseControllerHandleTossFailure(
    {
      orderId: orderId as string,
      code: code as string,
      message: message as string,
    },
    {
      request: { accessToken },
      query: {
        enabled: !!orderId && !!accessToken,
        queryKey: getPurchaseControllerHandleTossFailureQueryKey({
          orderId: orderId as string,
          code: code as string,
          message: message as string,
        }),
      },
    },
  );

  const handleBackPurchase = () => {
    router.push({
      pathname: "/payment",
      query: {
        courseId: courseId,
        cycleId: cycleId,
      },
    });
  };

  if (isLoading) {
    <FailContainer>
      <LoadingSpinnerWrapper>
        <CircularProgress />
      </LoadingSpinnerWrapper>
      <Description>{t("processing")}</Description>
    </FailContainer>;
  }

  return (
    <FailContainer>
      <ErrorIcon icon={ICON_SVG.LINE.SIZE_24.ICON_WARNING} />
      <PaymentTitle>{t("payment_failed")}</PaymentTitle>
      <Description>{message ?? t("unknown")}</Description>
      <BackButton
        type={"submit"}
        styleType={BUTTON_TYPE.OUTLINE}
        onClick={handleBackPurchase}
        disabled={false}
      >
        {t("go_back")}
      </BackButton>
    </FailContainer>
  );
};

const FailContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 560px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* gap: 24px; */
  background: ${({ theme }) => theme.colors.brand.primary.default};
`;

const ErrorIcon = styled(Icon)`
  flex-shrink: 0;
  width: 24px;

  rect {
    fill: ${({ theme }) => theme.colors.state.error.default};
  }

  circle {
    &:first-of-type {
      stroke: ${({ theme }) => theme.colors.state.error.default};
    }

    &:last-of-type {
      fill: ${({ theme }) => theme.colors.state.error.default};
    }
  }
`;

const PaymentTitle = styled.h1`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const Description = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  text-align: center;
`;

const BackButton = styled(Button)`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    ${({ theme }) => theme.typography.body2.b};
    width: 300px;
    border-radius: 8px;
    padding: 12px 16px;
    margin-top: 24px;
    color: ${({ theme }) => theme.colors.text.high.white};

    &:focus {
      background-color: ${({ theme }) => theme.colors.brand.primary.hover};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.brand.primary.pressed};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.brand.white.disabled};
      color: ${({ theme }) => theme.colors.text.disabled.white};
    }
  }
`;

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  padding: 24px;
`;
