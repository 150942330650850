import { QuillRenderer } from "@/components/common";
import { ClassCardSection } from "@/components/domain";
import { MAX_WIDTH } from "@/constants/style/layout";
import styled from "@emotion/styled";
import { useAcademyControllerAcademyProfilePublic } from "@shared/generated/api/fn/kac/academy/academy";
import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { getAcademyBanner } from "@shared/lib/utils/academyData";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const AcademyDetailsTemplate = () => {
  const router = useRouter();
  const { academyId } = router.query;
  const { isTabletSm } = useMediaQueryMaxWidth();

  const { data: academyData } = useAcademyControllerAcademyProfilePublic(
    Number(academyId),
  );

  const mediaSrc = useMemo(
    () => getAcademyBanner(academyData?.media ?? [], isTabletSm),
    [academyData?.media, isTabletSm],
  );

  const isVideoFile = (filePath: string | undefined): boolean => {
    if (!filePath) return false;
    return /\.(mp4|webm|ogg)$/i.test(filePath);
  };

  const introMedia = academyData?.media.find(
    (item) => item.type === "INTRO_IMAGE",
  )?.filePath;

  const { t } = useTranslation();

  return (
    <DetailPageContainer>
      <ContentsWrapper>
        <ThumbnailWrapper>
          <img src={mediaSrc} />
        </ThumbnailWrapper>
        <AcademyContentSection>
          <InfoWrapper>
            <H1Bold>{t("academy_introduction")}</H1Bold>
            <IntroductionSection>
              <ThumbnailWrapper>
                {introMedia ? (
                  isVideoFile(introMedia) ? (
                    <video
                      src={introMedia}
                      controls
                      muted
                    />
                  ) : (
                    <img
                      src={introMedia}
                      alt="intro image"
                    />
                  )
                ) : (
                  <p>No media available</p>
                )}
              </ThumbnailWrapper>
              <Introduction>
                <TopWrapper>
                  <H1Bold>{academyData?.name}</H1Bold>
                  <H3M>{academyData?.summary}</H3M>
                </TopWrapper>
                <BottomWrapper>
                  <Body2M>
                    {academyData?.content && (
                      <QuillRenderer
                        ops={JSON.parse(
                          JSON.stringify(academyData?.content?.ops),
                        )}
                      />
                    )}
                  </Body2M>
                </BottomWrapper>
              </Introduction>
            </IntroductionSection>
          </InfoWrapper>
          <InfoWrapper>
            <div>
              <H1Bold>{academyData?.instructorIntroTitle}</H1Bold>
              <Body1M>{academyData?.instructorIntroOverview}</Body1M>
            </div>
            <CardWrapper>
              {academyData?.instructorIntros?.map((instructor) => (
                <CardImgWrapper key={instructor.id}>
                  <img src={instructor?.imagePath} />
                </CardImgWrapper>
              ))}
            </CardWrapper>
          </InfoWrapper>
          <InfoWrapper>
            <div>
              <H1Bold>{academyData?.featureTitle}</H1Bold>
              <Body1M>{academyData?.featureOverview}</Body1M>
            </div>
            <PointsSection>
              {academyData?.features.map((feature, idx) => (
                <PointCard key={idx}>
                  <Body2B>POINT {idx + 1}</Body2B>
                  <H2Bold>{feature.title}</H2Bold>
                  <Body2M>{feature.description}</Body2M>
                </PointCard>
              ))}
            </PointsSection>
          </InfoWrapper>
          <InfoWrapper>
            <div>
              <H1Bold>{academyData?.courseLinkTitle}</H1Bold>
              <Body1M>{academyData?.courseLinkOverview}</Body1M>
            </div>
            {academyData && (
              <ClassCardSection
                courseData={academyData?.courses}
                classSection={""}
              />
            )}
          </InfoWrapper>
        </AcademyContentSection>
      </ContentsWrapper>
    </DetailPageContainer>
  );
};

const DetailPageContainer = styled.div`
  background: ${({ theme }) => theme.colors.brand.primary.default};
  color: ${({ theme }) => theme.colors.text.high.white} !important;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 530px);
  display: flex;
  justify-content: center;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img,
  video {
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;

const AcademyContentSection = styled.div`
  width: 100%;
  padding: 60px 0 120px;
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const H1Bold = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.brand.white.default};
`;

const H2Bold = styled.div`
  ${({ theme }) => theme.typography.h2.b};
  color: ${({ theme }) => theme.colors.brand.white.default};
`;

const IntroductionSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: ${({ theme }) => theme.colors.brand.secondary.default};
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  height: 100%;
  max-height: 420px;
  img,
  video {
    width: 100%;
  }
  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
  }
  ${({ theme }) => theme.media.tabletSm} {
    grid-template-columns: repeat(1, 1fr);
    max-height: 1000px;
  }
  ${({ theme }) => theme.media.mobile} {
    grid-template-columns: repeat(1, 1fr);
    max-height: 600px;
  }
`;

const Introduction = styled.div`
  display: flex;
  flex-direction: column;
`;

const H3M = styled.div`
  ${({ theme }) => theme.typography.h3.m};
  color: ${({ theme }) => theme.colors.brand.white.default};
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 24px;
`;

const BottomWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  gap: 8px;
  border-top: 1px solid #ffffff1a;
  max-height: 260px;
  ${({ theme }) => theme.media.mobile} {
    max-height: 200px;
  }
`;

const Body2M = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.brand.tertiary.default};
`;

const Body2B = styled.div`
  ${({ theme }) => theme.typography.body2.b};
  color: #ffffff;
`;

const Body1M = styled.div`
  ${({ theme }) => theme.typography.body1.m};
  color: #aea9b4;
`;

const CardWrapper = styled.div`
  width: 100%;
  gap: 24px 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  ${({ theme }) => theme.media.tabletSm} {
    display: flex;
    overflow-x: auto;
  }
`;

const CardImgWrapper = styled.div`
  ${({ theme }) => theme.media.tabletSm} {
    min-width: 285px;
  }
  img {
    width: 100%;
    max-height: 375px;
    object-fit: cover;
    border-radius: 8px;
  }
`;

const PointsSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 20px;
  ${({ theme }) => theme.media.tabletSm} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const PointCard = styled.div`
  background: ${({ theme }) => theme.colors.brand.secondary.default};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
`;
