import styled from "@emotion/styled";
import { KeyboardEvent, useEffect, useState } from "react";
import ArrowDownIcon from "@shared/lib/assets/images/icons/line/16/line-arrow-down.svg";
import SearchIcon from "@shared/lib/assets/images/icons/line/24/line-search.svg";
import { useTranslation } from "react-i18next";

interface InquirySearchBarProps {
  selected: string;
  onSearch: (selected: string, searchValue: string) => void;
}

export const InquirySearchBar = ({
  selected,
  onSearch,
}: InquirySearchBarProps) => {
  const { t } = useTranslation();
  const [searchType, setSearchType] = useState<string>(t("title"));
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);
  const selectItem = (item: string) => {
    setSearchType(item);
    setSearchValue("");
    setDropdownOpen(false);
    onSearch(searchType, searchValue);
  };

  const handleSearch = () => {
    onSearch(searchType, searchValue);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    setSearchType(t("title"));
    setSearchValue("");
    setDropdownOpen(false);
  }, [selected]);

  return (
    <Container>
      <DropdownContainer>
        <DropdownButton onClick={toggleDropdown}>
          {searchType}
          <Icon>
            <ArrowDownIcon />
          </Icon>
        </DropdownButton>
        {dropdownOpen && (
          <DropdownList>
            {[t("title"), t("content")].map((item) => (
              <DropdownItem
                key={item}
                selected={item === searchType}
                onClick={() => selectItem(item)}
              >
                {item}
              </DropdownItem>
            ))}
          </DropdownList>
        )}
      </DropdownContainer>
      <Input
        type="text"
        placeholder={t("search")}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <SearchButton onClick={handleSearch}>
        <SearchIcon />
      </SearchButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  background-color: #fff;
  width: 100%;
  max-width: 415px;
  height: 100%;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const DropdownButton = styled.button`
  border: none;
  background: transparent;
  width: 100%;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.high.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 33px;
  left: -9px;
  z-index: 10;
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  box-shadow: 4px 4px 24px 0px #00000026;
  list-style: none;
  margin: 0;
  padding: 4px;
  width: 83px;
  ${({ theme }) => theme.typography.body1.r}
`;

const DropdownItem = styled.li<{ selected: boolean }>`
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  background: ${({ selected }) => (selected ? "#2065EC14" : "transparent")};
  ${({ theme }) => theme.typography.body1.r}
  color: ${({ theme, selected }) =>
    selected ? theme.colors.sub.blue.default : theme.colors.text.high.black};
  &:hover {
    background: ${({ theme }) => theme.colors.brand.white.hover};
    color: ${({ theme }) => theme.colors.text.high.black};
  }
`;

const Input = styled.input`
  padding-left: 16px;
  border: none;
  outline: none;
  flex-grow: 1;
  width: 100%;
  max-width: 310px;
  ${({ theme }) => theme.typography.body1.r}
  color: ${({ theme }) => theme.colors.text.high.black};
  &::placeholder {
    color: ${({ theme }) => theme.colors.icon.disabled.black};
  }
`;

const SearchButton = styled.button`
  display: flex;
  border: none;
  cursor: pointer;
  font-size: 14px;
  ${({ theme }) => theme.typography.body1.r}
  color: ${({ theme }) => theme.colors.icon.inactive.black};
`;

const Icon = styled.span`
  margin-left: 8px;
  display: flex;
  color: ${({ theme }) => theme.colors.icon.inactive.black};
`;
