import styled from "@emotion/styled";

import { Button } from "@shared/lib/components/common/atom/button";
import { Icon } from "@shared/lib/components/common/atom/icon";
import { TextField } from "@shared/lib/components/common/atom/text-feild";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { useTranslation } from "react-i18next";

interface LoginFormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  register: any; // 적절한 타입으로 수정 필요
  errors: any; // 적절한 타입으로 수정 필요
  isShowPassword: boolean;
  onShowPassword: () => void;
  errorMessage?: string;
  isValid: boolean;
  isPending: boolean;
}

export const LoginForm = ({
  onSubmit,
  register,
  errors,
  isShowPassword,
  onShowPassword,
  errorMessage,
  isValid,
  isPending,
}: LoginFormProps) => {
  const { t } = useTranslation();
  return (
    <form onSubmit={onSubmit}>
      <LoginFormContainer>
        <LoginFormInputContainer>
          <LoginFormTextField
            type="text"
            label={t("email_id")}
            placeholder={t("please_enter_your_email_id")}
            {...register("email")}
            isError={!!errors.email}
            message={errors?.email?.message}
          />

          <LoginFormTextField
            type={isShowPassword ? "text" : "password"}
            label={t("password")}
            placeholder={t("enter_password")}
            {...register("password")}
            isError={!!errors.password}
            message={errors?.password?.message}
            endAdornment={
              <a onClick={onShowPassword}>
                <Icon
                  icon={
                    isShowPassword
                      ? ICON_SVG.LINE.SIZE_18.ICON_EYE
                      : ICON_SVG.LINE.SIZE_18.ICON_EYE_OFF
                  }
                />
              </a>
            }
          />
        </LoginFormInputContainer>

        <LoginButtonContainer>
          {errorMessage && <Message>{errorMessage}</Message>}
          <LoginButton
            styleType={BUTTON_TYPE.FILL}
            disabled={!isValid || isPending}
          >
            {t("signin")}
          </LoginButton>
        </LoginButtonContainer>
      </LoginFormContainer>
    </form>
  );
};

const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LoginFormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LoginFormTextField = styled(TextField)`
  gap: 4px;
`;

const LoginButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Message = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.state.error.default};
  padding-bottom: 3px;
  &.is-error {
    padding-top: 8px;
  }
`;

const LoginButton = styled(Button)`
  width: 100%;

  &.btn-fill {
    border-radius: 4px;
  }
`;
