import styled from "@emotion/styled";
import { useCourseControllerGetSectionsGlobal } from "@shared/generated/api/fn/kac/course/course";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

export const LiveClassCategory = () => {
  const router = useRouter();
  const lang = (router.locale as "ko" | "en") || "ko";
  const { data: sectionData } = useCourseControllerGetSectionsGlobal(lang, {
    visible: true,
  });
  const { t } = useTranslation();
  console.log(t("kac_live_class"));

  return (
    <LiveClassCategoryContainer>
      <CategoryWrapper>
        <Title>{t("kac_live_class")}</Title>
        <CategorySection>
          {sectionData?.map((section) => (
            <CategoryItem
              key={section.id}
              onClick={() => router.push(`/class/live/${section.router}`)}
            >
              <CategoryImage
                src={`/images/main/${section.router}.svg`}
                alt="Category"
              />
              <CategoryName>{section.name}</CategoryName>
            </CategoryItem>
          ))}
        </CategorySection>
      </CategoryWrapper>
    </LiveClassCategoryContainer>
  );
};

const LiveClassCategoryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.tabletSm} {
    padding-bottom: 40px;
  }
`;

const CategoryWrapper = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 0 16px;
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.h1.b}
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const CategorySection = styled.div`
  display: flex;
  gap: 16px;
`;

const CategoryItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
  gap: 8px;
`;

const CategoryImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const CategoryName = styled.div`
  ${({ theme }) => theme.typography.body2.b}
  color: ${({ theme }) => theme.colors.text.high.white};
  text-align: center;
`;
