import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { IntroductionSection, ReviewSection, ScheduleSelection } from ".";
import { CourseResponseDto } from "@shared/generated/api/model";
import { QuillRenderer } from "@/components";
import { throttle } from "lodash";
import { useTranslation } from "react-i18next";

export const ScrollTab = ({ classData }: { classData: CourseResponseDto }) => {
  const { t } = useTranslation();
  const sectionsRef = useRef<(HTMLElement | null)[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isScrollingByClick, setIsScrollingByClick] = useState(false);

  // ✅ 스크롤 완료 감지 함수
  const detectScrollEnd = () => {
    let lastPosition = window.scrollY;
    let isScrolling = true;

    const checkScroll = () => {
      const currentPosition = window.scrollY;

      if (lastPosition === currentPosition) {
        isScrolling = false;
        setIsScrollingByClick(false); // ✅ 스크롤이 완전히 멈춘 후 감지 활성화
      } else {
        lastPosition = currentPosition;
        requestAnimationFrame(checkScroll); // ✅ 계속 감지
      }
    };

    requestAnimationFrame(checkScroll);
  };

  // ✅ 스크롤 시 현재 보이는 섹션을 감지하여 `activeTab` 자동 업데이트
  const handleScroll = throttle(() => {
    if (isScrollingByClick) return; // ✅ 클릭으로 스크롤 중일 때 감지 중단

    const scrollPosition = window.scrollY + 180; // 오프셋 고려
    let newActiveTab = activeTab;

    sectionsRef.current.forEach((section, index) => {
      if (section) {
        const { offsetTop, offsetHeight } = section;
        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          newActiveTab = index;
        }
      }
    });

    if (newActiveTab !== activeTab) {
      setActiveTab(newActiveTab);
    }
  }, 200); // ✅ 200ms마다 실행 제한 (성능 최적화)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeTab, isScrollingByClick]);

  // ✅ `easeOutQuad` 적용하여 부드럽지만 빠르게 이동
  const easeOutQuad = (t: number) => t * (2 - t); // ✅ 감속 이동 공식

  const scrollToSection = (index: number) => {
    const section = sectionsRef.current[index];
    if (!section) return;

    setIsScrollingByClick(true); // ✅ 클릭으로 스크롤 중 상태 변경

    const targetOffset = section.offsetTop - 160;
    const startPosition = window.scrollY;
    const distance = targetOffset - startPosition;
    const duration = 500;
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // ✅ 0~1 범위 내에서 진행률 계산
      const easedProgress = easeOutQuad(progress); // ✅ 감속 이동 적용

      window.scrollTo(0, startPosition + distance * easedProgress);

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      } else {
        setActiveTab(index); // ✅ 스크롤이 끝난 후 `activeTab` 변경
        setIsScrollingByClick(false); // ✅ 스크롤 애니메이션 완료 후 감지 활성화
      }
    };

    requestAnimationFrame(animateScroll);
  };

  return (
    <Container>
      <TabHeader>
        <TabButton
          isActive={activeTab === 0}
          onClick={() => scrollToSection(0)}
        >
          {t("schedule")}
        </TabButton>
        <TabButton
          isActive={activeTab === 1}
          onClick={() => scrollToSection(1)}
        >
          {t("class_introduction")}
        </TabButton>
        <TabButton
          isActive={activeTab === 2}
          onClick={() => scrollToSection(2)}
        >
          {t("review")}
        </TabButton>
      </TabHeader>

      <ContentContainer>
        <Section
          ref={(el) => {
            sectionsRef.current[0] = el;
          }}
        >
          <ScheduleSelection />
        </Section>
        <Section
          ref={(el) => {
            sectionsRef.current[1] = el;
          }}
        >
          <IntroductionSection>
            {classData?.contents?.pcContent && (
              <QuillRenderer
                ops={JSON.parse(
                  JSON.stringify(classData.contents.pcContent.ops),
                )}
              />
            )}
          </IntroductionSection>
        </Section>
        <Section
          ref={(el) => {
            sectionsRef.current[2] = el;
          }}
        >
          <ReviewSection />
        </Section>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TabHeader = styled.div`
  display: flex;
  padding: 40px 0 0;
  position: sticky;
  top: 100px;
  background: ${({ theme }) => theme.colors.gray.gray0};
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.disabled.white};
  ${({ theme }) => theme.media.tablet} {
    top: 60px;
  }
`;

const TabButton = styled.button<{ isActive: boolean }>`
  min-width: 100px;
  padding: 0 16px 16px;
  font-size: 16px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.text.high.white : theme.colors.text.medium.white};
  position: relative;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 22px;
  letter-spacing: -0.4px;
  gap: 4px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 2px;
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.icon.active.white : ""};
    transition: background-color 0.3s ease;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Section = styled.section`
  padding: 40px 0;
  box-sizing: border-box;
`;
