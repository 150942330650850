import { Box, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClassCard from "@/components/template/my/class/ClassCard";
import { NoClassPlaceholder } from "@/components/template/my/class/NoClassPlaceholder";
import { useMyWishlistCourses } from "@/hooks/me/use-my-wishlist-courses";
import { useMediaQueryMaxWidth } from "@shared/lib/hooks/use-media-query-max-width";
import { useRouter } from "next/router";
import { PageHeader } from "@/components/ui/headers";
import { useTranslation } from "react-i18next";
import {
  getUserControllerCoursesQueryKey,
  useUserControllerCourses,
} from "@shared/generated/api/fn/kac/user/user";
import { useAccessToken } from "@/hooks/auth/use-access-token";

export const MyClassTemplate = () => {
  const { t } = useTranslation();
  const router = useRouter();
  // const { data: myCoursesData, isLoading: isMyCoursesLoading } = useMyCourse();
  const { accessToken } = useAccessToken();
  const { data: myCoursesData, isLoading: isMyCoursesLoading } =
    useUserControllerCourses(
      {},
      {
        request: { accessToken },
        query: {
          enabled: !!accessToken,
          queryKey: getUserControllerCoursesQueryKey(),
        },
      },
    );
  const { data: myWishlistData, isLoading: isMyWishlistLoading } =
    useMyWishlistCourses();
  const { isMobile, isTabletSm } = useMediaQueryMaxWidth();
  console.log(myCoursesData);
  const gridColumns = isMobile ? 1 : isTabletSm ? 2 : 3;

  const handleClick = () => {
    router.push("/my/schedule");
  };

  const CourseListSkeleton = ({
    section,
  }: {
    section: "enrolled" | "wishlist";
  }) => (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xs: "repeat(1, minmax(343px, 1fr))",
          sm: "repeat(2, minmax(328px, 1fr))",
          md: "repeat(3, minmax(360px, 1fr))",
          lg: "repeat(3, minmax(272px, 1fr))",
        },
        gap: 2,
        gridAutoFlow: "row",
        justifyItems: "center",
        width: "100%",
      }}
    >
      {Array.from({ length: gridColumns }).map((_, index) => (
        <Box
          key={`${section}-skeleton-${index}`}
          sx={{
            width: {
              xs: "343px",
              sm: "328px",
              md: "360px",
              lg: "272px",
            },
            height: {
              xs: "342px",
              sm: "352px",
              md: "334px",
              lg: "299px",
            },
          }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{ borderRadius: 2 }}
          />
        </Box>
      ))}
    </Box>
  );

  return (
    <Box
      sx={{
        maxWidth: "100%",
        bgcolor: "transparent",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <PageHeader title={t("my_class")} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: { xs: 2, sm: 0 },
        }}
      >
        <Box>
          <StyledBadge selected>LIVE</StyledBadge>
          <StyledBadge disabled>VOD</StyledBadge>
        </Box>
        <StyledButton onClick={handleClick}>
          <CalendarTodayIcon
            sx={{ width: 18, height: 18, maxWidth: "145px", pl: "2px" }}
          />
          {t("check_schedule")}
          <ArrowForwardIosIcon />
        </StyledButton>
      </Box>

      {/* Class List */}
      <Box sx={{ p: 0 }}>
        <Box
          sx={{
            mb: 3,
          }}
        >
          <Box
            sx={{
              fontSize: { xs: "1rem", sm: "1.125rem" },
              fontWeight: "bold",
              mb: { xs: 2, sm: 1 },
            }}
          >
            {t("attend_live_class")}
          </Box>
          {isMyCoursesLoading ? (
            <CourseListSkeleton section="enrolled" />
          ) : !myCoursesData?.count ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <NoClassPlaceholder placeholder={t("no_purchased_lectures")} />
            </Box>
          ) : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(343px, 1fr))",
                  sm: "repeat(2, minmax(328px, 1fr))",
                  md: "repeat(3, minmax(360px, 1fr))",
                  lg: "repeat(3, minmax(272px, 1fr))",
                },
                gap: 2,
                gridAutoFlow: "row", // 순서대로 위에서 아래로
                justifyItems: "center", // 각 그리드 아이템 가운데 정렬
                width: "100%",
              }}
            >
              {myCoursesData &&
                myCoursesData?.rows?.map((course) => (
                  <Box key={`enrolled-${course.id}`}>
                    <ClassCard course={course} />
                  </Box>
                ))}
            </Box>
          )}
        </Box>

        <Box>
          <Box
            sx={{
              fontSize: { xs: "1rem", sm: "1.125rem" },
              fontWeight: "bold",
              mb: { xs: 2, sm: 1 },
            }}
          >
            {t("liked_live_classes")}
          </Box>
          {isMyWishlistLoading ? (
            <CourseListSkeleton section="wishlist" />
          ) : !myWishlistData?.count ? (
            <NoClassPlaceholder placeholder={t("no_liked_classes")} />
          ) : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(343px, 1fr))",
                  sm: "repeat(2, minmax(328px, 1fr))",
                  md: "repeat(3, minmax(360px, 1fr))",
                  lg: "repeat(3, minmax(272px, 1fr))",
                },
                gap: 2,
                gridAutoFlow: "row",
                justifyItems: "center",
                width: "100%",
              }}
            >
              {myWishlistData?.rows?.map((course) => (
                <Box key={`wishlist-${course.id}`}>
                  <ClassCard course={course} />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const StyledBadge = styled(Box)<{ selected?: boolean; disabled?: boolean }>(
  ({ selected, disabled }) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    marginRight: "8px",
    height: "36px",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    minWidth: "61px",
    cursor: disabled ? "default" : "pointer",
    transition: "all 0.2s ease",

    // 기본 스타일 (비활성)
    backgroundColor: "#E6E6E6",
    color: "#999999",
    opacity: 0.5,

    // LIVE 선택 시 스타일
    ...(selected &&
      !disabled && {
        backgroundColor: "#2065EC",
        color: "#ffffff",
        opacity: 1,
      }),

    // hover 효과 (VOD는 제외)
    ...(!disabled && {
      "&:hover": {
        backgroundColor: "#2065EC",
        color: "#ffffff",
        opacity: 0.9,
      },
    }),
  }),
);

const StyledButton = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "8px 16px",
  gap: "8px",
  borderRadius: "8px",
  border: "1px solid #00000061",
  backgroundColor: "#FFFFFF",
  cursor: "pointer",
  color: "#333",
  fontSize: "14px",
  fontWeight: "500",
  letterSpacing: "-0.4px",
  whiteSpace: "nowrap",
  maxHeight: "40px",
  [`@media (max-width: 430px)`]: {
    gap: "4px",
    padding: "6px 10px",
    maxHeight: "36px",
  },
}));
