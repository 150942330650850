import styled from "@emotion/styled";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export const IntroductionSection = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <IntroductionContainer>
      <IntroductionTitle>{t("class_introduction")}</IntroductionTitle>
      {children}
    </IntroductionContainer>
  );
};

const IntroductionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
  }
`;

const IntroductionTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b}
  color: ${({ theme }) => theme.colors.text.high.white}
`;
