import styled from "@emotion/styled";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { DeltaStatic } from "react-quill-new";

interface QuillRendererProps {
  ops: DeltaStatic[];
}

export const QuillRenderer = ({ ops }: QuillRendererProps) => {
  const converter = new QuillDeltaToHtmlConverter(ops, {});
  const html = converter.convert();

  return <EditorWrapper dangerouslySetInnerHTML={{ __html: html }} />;
};

const EditorWrapper = styled.div`
  width: 100%;
  img,
  video {
    width: 100%;
  }

  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
  }
`;
