import styled from "@emotion/styled";

import { MAX_WIDTH } from "@/constants/style/layout";
import {
  AcademyBrandBanner,
  AcademyBanner,
  ClassCardSection,
  MainBanner,
  ReviewsBanner,
  TeacherApplyBanner,
  LiveClassCategory,
  TitleDescriptionSection,
} from "@/components/domain";
import { useContentsControllerGetGlobalContents } from "@shared/generated/api/fn/kac/contents/contents";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

export const MainTemplate = () => {
  const { t } = useTranslation();
  const { accessToken } = useAccessToken();
  const { data: mainData } = useContentsControllerGetGlobalContents("ko", {
    request: { accessToken },
  });
  console.log(mainData);
  const router = useRouter();
  const locale = router.locale || "ko";
  const isKo = locale === "ko";
  const {
    BANNER: bannerData,
    ACADEMY: academyData,
    COURSE: courseData,
  } = mainData || {};

  return (
    <MainTemplateContainer>
      {bannerData && <MainBanner bannerData={bannerData} />}
      <MainTemplateInnerContainer>
        <ClassSectionWrapper>
          {courseData &&
            courseData[0]?.contents.length !== 0 &&
            courseData.map((course) => (
              <section
                key={course.title}
                className="p16"
              >
                <TitleDescriptionSection
                  title={course?.title || ""}
                  description={course?.description || ""}
                />
                <ClassCardSection
                  courseData={course?.contents}
                  classSection={null}
                />
              </section>
            ))}

          {isKo && academyData && academyData?.academyBanners.length !== 0 && (
            <section>
              <div className="p16">
                <TitleDescriptionSection
                  title={t("kac_x_academy_brand")}
                  description={t("kac_proposes_academy_brands")}
                />
              </div>
              <AcademyBrandBanner academyData={academyData} />
            </section>
          )}

          {isKo && (
            <section>
              <AcademyBanner isLandingPage={true} />
            </section>
          )}

          <section>
            <ReviewsBanner />
          </section>

          {isKo && (
            <section>
              <TeacherApplyBanner />
            </section>
          )}

          <section>
            <LiveClassCategory />
          </section>
        </ClassSectionWrapper>
      </MainTemplateInnerContainer>
    </MainTemplateContainer>
  );
};

const MainTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.brand.primary.default};
`;

const MainTemplateInnerContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
`;

const ClassSectionWrapper = styled.section`
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;
  margin: 0 auto;
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  gap: 80px;
  ${({ theme }) => theme.media.tabletSm} {
    padding: 40px 0;
    gap: 40px;
  }
  .p16 {
    padding: 0 16px;
  }
`;
