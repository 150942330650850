import styled from "@emotion/styled";
import { formatDate } from "@shared/lib/utils/date/formatSchedule";
import { priceToLocaleString } from "@shared/lib/utils/price";
import {
  convertPurchaseType,
  getStatusInfo,
  PaymentStatus,
  PurchaseTypes,
} from "../../payment";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { Button } from "@shared/lib/components/common/atom/button";
import {
  AmountInfoDto,
  PurchaseByIdResultDto,
  UserInfoDto,
} from "@shared/generated/api/model";
import { useTranslation } from "react-i18next";

interface OrderDetailsProps {
  amountInfo: AmountInfoDto;
  purchaseResult: PurchaseByIdResultDto;
  userInfo: UserInfoDto;
  receipt: string;
}

export const OrderDetailsContent = ({
  amountInfo,
  purchaseResult,
  userInfo,
  receipt,
}: OrderDetailsProps) => {
  const { t } = useTranslation();
  const { paidAmount, usedPoints } = amountInfo;
  const {
    purchaseType,
    purchaseStatus,
    requestedAt,
    completedAt,
    virtualAccount,
  } = purchaseResult;
  const { text, color } = getStatusInfo(purchaseStatus as PaymentStatus) || {};

  return (
    <OrderContentContainer>
      <OrderInfoWrapper>
        <ContentWrapper>
          <ClassTitle>{t("payment_amount")}</ClassTitle>
          <PriceText>
            {priceToLocaleString(paidAmount)}
            {t("won")}
          </PriceText>
        </ContentWrapper>
        <ContentWrapper>
          <StatusText>{t("use_points")}</StatusText>
          <StatusText>
            {`${priceToLocaleString(usedPoints)}`}
            {t("won")}
          </StatusText>
        </ContentWrapper>
      </OrderInfoWrapper>
      <SplitLine />
      <OrderInfoWrapper>
        <ContentWrapper>
          <StatusText>{t("status")}</StatusText>
          <StatusButton color={color}>{t(`${text}`)}</StatusButton>
        </ContentWrapper>
        <ContentWrapper>
          <StatusText>{t("payment_method")}</StatusText>
          <ValueText>
            {purchaseType &&
              t(convertPurchaseType(purchaseType as PurchaseTypes))}
          </ValueText>
        </ContentWrapper>
        {virtualAccount && (
          <>
            <ContentWrapper>
              <StatusText>{t("deposit_information")}</StatusText>
              <ValueText>
                {`${virtualAccount?.bank} ${virtualAccount?.accountNumber}` ||
                  ""}
              </ValueText>
            </ContentWrapper>
            <ContentWrapper>
              <StatusText>{t("deposit_deadline")}</StatusText>
              <ValueText>
                {virtualAccount?.dueDate &&
                  formatDate(new Date(virtualAccount?.dueDate), {
                    withTime: true,
                  })}
              </ValueText>
            </ContentWrapper>
          </>
        )}
        <ContentWrapper>
          <StatusText>{t("order_time")}</StatusText>
          <ValueText>
            {requestedAt &&
              formatDate(new Date(requestedAt), { withTime: true })}
          </ValueText>
        </ContentWrapper>
        <ContentWrapper>
          <StatusText>{t("completed_time")}</StatusText>
          <ValueText>
            {completedAt &&
              formatDate(new Date(completedAt), { withTime: true })}
          </ValueText>
        </ContentWrapper>
      </OrderInfoWrapper>
      <SplitLine />
      <OrderInfoWrapper>
        <ContentWrapper>
          <StatusText>{t("orderer")}</StatusText>
          <ValueText>{userInfo?.name}</ValueText>
        </ContentWrapper>
        <ContentWrapper>
          <StatusText>{t("student")}</StatusText>
          <ValueText>{userInfo?.name}</ValueText>
        </ContentWrapper>
        <ContentWrapper>
          <StatusText>{t("phone_number")}</StatusText>
          <ValueText>{userInfo?.phoneNumber}</ValueText>
        </ContentWrapper>
        <ContentWrapper>
          <StatusText>{t("email")}</StatusText>
          <ValueText>{userInfo?.email}</ValueText>
        </ContentWrapper>
      </OrderInfoWrapper>
      {purchaseStatus === "COMPLETED" && purchaseType !== "POINT" && (
        <OrderInfoWrapper>
          <ReceiptButton
            type={"submit"}
            styleType={BUTTON_TYPE.OUTLINE}
            onClick={() => receipt && window.open(receipt, "_blank")}
            disabled={false}
          >
            {t("print_receipt")}
          </ReceiptButton>
        </OrderInfoWrapper>
      )}
    </OrderContentContainer>
  );
};

const OrderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const OrderInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ClassTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
`;

const PriceText = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.state.error.default};
`;

const SplitLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray90};
`;

const StatusText = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.black};
  display: flex;
  align-items: center;
`;

const StatusButton = styled.div<{ color: string }>`
  padding: 4px 8px;
  border-radius: 8px;
  background: ${({ theme, color }) =>
    color ? color : theme.colors.sub.green.default};
  ${({ theme }) => theme.typography.body3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
  display: flex;
  align-items: center;
`;

const ValueText = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  display: flex;
  align-items: center;
`;

const ReceiptButton = styled(Button)`
  &.btn-${BUTTON_TYPE.OUTLINE.toLowerCase()} {
    ${({ theme }) => theme.typography.body2.b};
    width: 100%;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.colors.text.disabled.black};
    color: ${({ theme }) => theme.colors.text.medium.black};

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.brand.white.hover};
    }
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.brand.white.hover};
    }
  }
`;
