import { AcademyMediaResponseDto } from "@shared/generated/api/model";

export const getAcademyBanner = (
  media: AcademyMediaResponseDto[],
  isTabletSm: boolean,
): string | undefined => {
  if (!media || media.length === 0) return undefined;

  const selectedType = isTabletSm ? "MOBILE_MAIN_BANNER" : "PC_MAIN_BANNER";
  const selectedMedia = media.find((item) => item.type === selectedType);

  return selectedMedia?.filePath;
};
