import { ContentCard } from "@/components/common";
import styled from "@emotion/styled";
import {
  calculateFinalPrice,
  priceToLocaleString,
} from "@shared/lib/utils/price";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
interface PriceProps {
  usedPoints: number;
  price: number;
  discountRate: number;
  onFinalPriceChange?: (finalPrice: number) => void;
  paymentStatus?: boolean | false;
}

export const PriceContent = ({
  usedPoints,
  price,
  discountRate,
  onFinalPriceChange,
  paymentStatus,
}: PriceProps) => {
  const { t } = useTranslation();

  const [finalPrice, setFinalPrice] = useState<number>(
    calculateFinalPrice(price, discountRate, usedPoints) || 0,
  );

  useEffect(() => {
    const newFinalPrice = calculateFinalPrice(price, discountRate, usedPoints);
    setFinalPrice(newFinalPrice || 0);

    // 부모 컴포넌트로 값 전달
    if (onFinalPriceChange) {
      onFinalPriceChange(newFinalPrice || 0);
    }
  }, [usedPoints, price, discountRate, onFinalPriceChange]);

  return (
    <ContentCard>
      <PriceContentContainer>
        {!paymentStatus && (
          <CommonWrapper>
            <CardTitle>{t("payment_amount")}</CardTitle>
          </CommonWrapper>
        )}
        <CommonWrapper>
          <PriceInfoWrapper>
            <PriceText>
              {!paymentStatus ? t("original_price") : t("class_price")}
            </PriceText>
            <PriceText>
              {priceToLocaleString(price)}
              {t("won")}
            </PriceText>
          </PriceInfoWrapper>
          <PriceInfoWrapper isDiscount={true}>
            <PriceText>
              {!paymentStatus ? t("discount_amount") : t("discount")}
            </PriceText>
            <PriceText>
              {price === 0
                ? 0
                : discountRate !== 0 &&
                  "-" + priceToLocaleString(price * discountRate)}
              {t("won")}
            </PriceText>
          </PriceInfoWrapper>
          <PriceInfoWrapper>
            <PriceText>{t("use_points")}</PriceText>
            <PriceText>
              {usedPoints === 0
                ? 0
                : usedPoints !== 0 && "-" + priceToLocaleString(usedPoints)}
              {t("won")}
            </PriceText>
          </PriceInfoWrapper>
          <DivideLine />
          <PriceInfoWrapper>
            <CardTitle>{t("total_payment_amount")}</CardTitle>
            <CardTitle>
              {priceToLocaleString(finalPrice || 0)}
              {t("won")}
            </CardTitle>
          </PriceInfoWrapper>
        </CommonWrapper>
      </PriceContentContainer>
    </ContentCard>
  );
};

const PriceContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CommonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CardTitle = styled.div`
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const PriceInfoWrapper = styled.div<{ isDiscount?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ isDiscount, theme }) =>
    isDiscount
      ? theme.colors.state.error.default
      : theme.colors.text.high.white};
`;

const PriceText = styled.div``;

const DivideLine = styled.div`
  position: relative;
  margin: 12px 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray.gray30};
  }
`;
