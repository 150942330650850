import { ContentCard } from "@/components/common";
import styled from "@emotion/styled";
import { Button } from "@shared/lib/components/common/atom/button";
import { CheckBox } from "@shared/lib/components/common/atom/check-box";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

interface PurchaseProps {
  handlePurchase: () => void;
  disabled: boolean;
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
}

export const PurchaseForm = ({
  handlePurchase,
  disabled,
  checked,
  onCheckedChange,
}: PurchaseProps) => {
  const { t } = useTranslation();
  const handleAgreePolicy = (event: ChangeEvent<HTMLInputElement>) => {
    onCheckedChange(event.target.checked);
  };

  return (
    <ContentCard>
      <PurchaseContainer>
        <CommonWrapper>
          <CardTitle>
            <CheckBox
              checked={checked}
              onChange={handleAgreePolicy}
            />
            {t("terms_of_agreement")}
          </CardTitle>
          <Description>{t("agreement_to_terms_and_conditions")}</Description>
        </CommonWrapper>
        <CommonWrapper>
          <TermsSection
            dangerouslySetInnerHTML={{ __html: t("refund_policy_content") }}
          />
        </CommonWrapper>
        <CommonWrapper>
          <NoticeTitle>{t("usage_guide")}</NoticeTitle>
          <DivideLine />
          <NoticeContent>
            <Subtitle className="title">
              {t("virtual_account_usage_guide")}
            </Subtitle>
            <Subtitle>{t("virtual_account_refund_policy")}</Subtitle>
          </NoticeContent>
          <NoticeContent>
            <Subtitle className="title">
              {t("cash_receipt_usage_guide")}
            </Subtitle>
            <Subtitle>{t("cash_receipt_information")}</Subtitle>
          </NoticeContent>
          <NoticeContent>
            <Subtitle className="title">{t("vat_law_change_notice")}</Subtitle>
            <Subtitle>{t("vat_law_change_details")}</Subtitle>
          </NoticeContent>
        </CommonWrapper>
        <BuyButton
          type={"submit"}
          styleType={BUTTON_TYPE.FILL}
          onClick={handlePurchase}
          disabled={disabled}
        >
          {t("pay")}
        </BuyButton>
      </PurchaseContainer>
    </ContentCard>
  );
};

const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CommonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CardTitle = styled.div`
  display: flex;
  gap: 4px;
  ${({ theme }) => theme.typography.h3.b};
  color: ${({ theme }) => theme.colors.text.high.white};
`;

const Description = styled.div`
  ${({ theme }) => theme.typography.body2.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  padding-left: 28px;
`;

const TermsSection = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.brand.white.disabled};
  background: ${({ theme }) => theme.colors.brand.primary.default};
  width: 100%;
  height: 100px;
  overflow-y: auto;
  ${({ theme }) => theme.typography.body3.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  padding: 12px 16px;
`;

const NoticeTitle = styled.div`
  ${({ theme }) => theme.typography.body2.b};
  color: ${({ theme }) => theme.colors.text.medium.white};
`;

const DivideLine = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray.gray30};
  }
`;

const NoticeContent = styled.div`
  ${({ theme }) => theme.typography.body3.m};
  color: ${({ theme }) => theme.colors.text.medium.white};
  .title {
    font-weight: 700;
  }
`;

const Subtitle = styled.p`
  margin: 0;
`;

const BuyButton = styled(Button)`
  &.btn-${BUTTON_TYPE.FILL.toLowerCase()} {
    ${({ theme }) => theme.typography.body2.b};
    width: 100%;
    border-radius: 8px;
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.colors.sub.blue.default};
    color: ${({ theme }) => theme.colors.text.high.white};

    &:focus {
      background-color: ${({ theme }) => theme.colors.sub.blue.pressed};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.sub.blue.hover};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.brand.white.disabled};
      color: ${({ theme }) => theme.colors.text.disabled.white};
    }
  }
`;
