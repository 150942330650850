import { useEffect } from "react";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";

import { openToast, TOAST_TYPE } from "@shared/lib/utils/toast";
import { useTranslation } from "react-i18next";

export const SnsLoginRedirect = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    const handleRedirect = async () => {
      const { errorCode, accessToken, socialId } = query;

      if (errorCode) {
        // errorCode가 포함되어 있으면, 토스트 모달 표시
        let message;
        switch (errorCode) {
          case "100006":
            message = t("email_already_registered");
            break;
          default:
            message = `[CODE: ${errorCode}] ${t("unknown_error_occurred")}`;
        }
        openToast(TOAST_TYPE.ERROR, message);
        router.replace(router.pathname, undefined, { shallow: true });
      } else if (accessToken) {
        // accessToken이 포함되어 있으면, nextAuth session에 저장 후 "/"로 라우팅
        await signIn("credentials", { accessToken, redirect: false });
        router.push("/");
      } else if (socialId) {
        // socialId가 있으면, query를 그대로 가지고 "/signup"으로 라우팅
        router.push({
          pathname: "/signup",
          query: { ...query },
        });
      }
    };

    handleRedirect();
  }, [query, router]);

  return null;
};
