import { Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { ICON_SVG } from "@shared/lib/constants/import/icon-svg";
import { useTranslation } from "react-i18next";

export const SnsLoginContainer = () => {
  const { t } = useTranslation();
  const onSnsLogin = async (type: string) => {
    const baseUrl = process.env.NEXT_PUBLIC_BACKEND_HOST;
    window.location.href = `${baseUrl}/auth/${type}`;
  };

  const snsLogins = [
    {
      icon: ICON_SVG.GRAPHIC.LOGIN.ICON_NAVER,
      label: "start_with_naver",
      themeKey: "naver" as const,
    },
    {
      icon: ICON_SVG.GRAPHIC.LOGIN.ICON_KAKAO,
      label: "start_with_kakao",
      themeKey: "kakao" as const,
    },
    {
      icon: ICON_SVG.GRAPHIC.LOGIN.ICON_GOOGLE,
      label: "start_with_google",
      themeKey: "google" as const,
    },
    // {
    //   icon: ICON_SVG.GRAPHIC.LOGIN.ICON_APPLE,
    //   label: "start_with_apple",
    //   themeKey: "apple" as const,
    // },
    // {
    //   icon: ICON_SVG.GRAPHIC.LOGIN.ICON_FACEBOOK,
    //   label: "start_with_facebook",
    //   themeKey: "facebook" as const,
    // },
  ];

  return (
    <Container>
      {snsLogins.map(({ icon, label, themeKey }) => (
        <SnsLoginButton
          key={themeKey}
          onClick={() => onSnsLogin(themeKey)}
          themeKey={themeKey}
        >
          <Icon icon={icon} />
          {t(`${label}`)}
        </SnsLoginButton>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SnsLoginButton = styled.button<{
  themeKey: keyof Theme["colors"]["sns"];
}>`
  border-radius: 4px;
  border: 1px solid;
  display: flex;
  height: 48px;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.4px;
  color: ${({ theme, themeKey }) => theme.colors.sns[themeKey].text};
  border-color: ${({ theme, themeKey }) =>
    themeKey === "google"
      ? theme.colors.gray.gray80
      : theme.colors.sns[themeKey].bg};
  background-color: ${({ theme, themeKey }) => theme.colors.sns[themeKey].bg};
`;
