export const reasonContent = [
  {
    title: "홈페이지 누적 방문자 수",
    quantity: "14만 명+",
    description: "*24년 3월 런칭 이후",
    img: "/images/academy/fill-group.png",
  },
  {
    title: "방문자 활동 건수",
    quantity: "36만 명+",
    description: "*이벤트 포함",
    img: "/images/academy/fill-play.png",
  },
  {
    title: "온라인 라이브 강의 운영시간",
    quantity: "5백 시간+",
    description: "*런칭 ~ 24년 6월 기준",
    img: "/images/academy/fill-time.png",
  },
] as const;

export const academyAdContent = [
  {
    title: "우리 학원 전용관 페이지 개설",
    description:
      "우리 학원만의 강점을 잘<br/>보여줄 수 있는 전용관 페이지 운영",
    img: "/images/academy/fill-academypage.png",
    subDescription: "",
  },
  {
    title: "홍보/마케팅 지원",
    description: "최적화 된 디지털 마케팅<br/>액션을 통한 수강생 유치",
    img: "/images/academy/fill-marketing.png",
    subDescription: "",
  },
  {
    title: "글로벌 진출",
    description: "실시간 번역 솔루션을 활용한<br/>해외 수강생 대상 강의 운영",
    subDescription: "*해당 기능은 현재 개발 중에 있습니다.",
    img: "/images/academy/fill-translation.png",
  },
] as const;

export const vocalTags = [
  "실기",
  "입시",
  "취미",
  "예대",
  "보컬",
  "작곡",
  "기악",
  "오디션",
  "미디",
  "싱어송",
  "댄스",
  "뮤지컬",
] as const;

export const compositionTags = [
  "작곡",
  "작사",
  "프로듀서",
  "PD",
  "미디",
  "DAW",
  "로직",
  "에이블톤",
  "큐베이스",
  "리드",
  "데뷔",
  "보컬",
] as const;

export const voiceActorTags = [
  "공채",
  "더빙",
  "애니메이션",
  "외화",
  "단문연기",
  "나레이션",
  "게임",
  "녹음",
  "오디오드라마",
  "오디오북",
  "낭독",
] as const;
