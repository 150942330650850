import { UserControllerInquiriesTypes } from "@shared/generated/api/model";

export const getInquiryTypePrefix = (
  type: UserControllerInquiriesTypes,
): string => {
  switch (type) {
    case UserControllerInquiriesTypes.COURSE_GENERAL:
      return "class";
    case UserControllerInquiriesTypes.COURSE_SCHEDULE:
      return "schedules";
    default:
      return "";
  }
};

export const getInquiryTypeText = (
  type: UserControllerInquiriesTypes,
): string => {
  switch (type) {
    case UserControllerInquiriesTypes.COURSE_GENERAL:
      return "inquire_class";
    case UserControllerInquiriesTypes.COURSE_SCHEDULE:
      return "inquire_schedule";
    default:
      return "";
  }
};
